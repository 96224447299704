import InfographicObject from 'objects/infographic'

export class InfographicComponent {
  constructor () {
    this.selectors = {
      _self: '[data-infographic]'
    }
  }

  run () {
    this.elements = {
      _self: document.querySelectorAll(this.selectors._self)
    }

    this.elements._self.forEach(infographic => new InfographicObject(infographic))
  }
}
