const Kernel = window.Kernel

export class ParallaxScroll {
  constructor () {
    this.selectors = {
      items: '[data-parallax-speed]',
      scroller: '[data-scrollbar="true"]'
    }

    this.tolerance = 150
    this.subscribed = false
    this.scroller = null
    this.listeners = {
      scroller: status => this.onScroller(status),
      scroll: e => this.onScroll(e),
      resize: e => this.onResize(e)
    }

    Kernel.subscribeTo('parallax.updateItems', () => this.run())
  }

  run () {
    this.elements = {
      items: document.querySelectorAll(this.selectors.items),
      scroller: document.querySelector(this.selectors.scroller)
    }

    if (this.elements.items.length > 0) {
      // if (this.scroller === null && this.elements.scroller !== null) {
      //   this.scroller = Scrollbar.get(this.elements.scroller)
      // }

      this.registerItems()

      if (this.subscribed === false) {
        this.subscribe()
      }
    }
  }

  getOffsetTop () {
    return this.scroller ? this.scroller.scrollTop : (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop)
  }

  registerItems () {
    this.items = []
    this.elements.items.forEach(item => {
      const itemSpeed = parseFloat(item.dataset.parallaxSpeed)

      this.items.push({
        element: item,
        box: item.getBoundingClientRect(),
        speed: itemSpeed,
        direction: item.dataset.parallaxDirection === 'X' ? 'X' : 'Y'
      })
    })
  }

  subscribe () {
    if (this.scroller !== null) {
      this.scroller.addListener(this.listeners.scroller)
    } else {
      Kernel.subscribeTo('window.scroll', this.listeners.scroll)
    }

    Kernel.subscribeTo('window.resize', this.listeners.resize)
    Kernel.subscribeTo('window.load', () => this.transformItems(this.getOffsetTop()))
    Kernel.subscribeTo('component.pagetransition.end', () => this.transformItems(this.getOffsetTop()))

    this.subscribed = true
  }

  onResize (e) {
    this.registerItems()
  }

  onScroller (status) {
    this.transformItems(status.offset.y)
  }

  onScroll (e) {
    this.transformItems(this.getOffsetTop())
  }

  transformItems (offsetTop) {
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight)

    this.items.forEach(item => {
      item.box = item.element.getBoundingClientRect()

      if (this.itemInViewport(item, offsetTop)) {
        const itemRelPos = windowHeight - item.box.top + this.tolerance
        const itemOffset = Math.round(itemRelPos * item.speed)

        item.element.style = `transform: translate${item.direction}(${itemOffset}px)`
      }
    })
  }

  itemInViewport (item, offsetTop) {
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight)

    // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
    return (item.box.top - this.tolerance <= windowHeight) && ((item.box.top + item.box.height + this.tolerance) >= 0)
  }

  destroy () {

  }
}
