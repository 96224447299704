import LoadJS from 'loadjs'

const Kernel = window.Kernel

const Transition = {
  element: document.querySelector('.transition--layer .transition__layer'),

  wait: callback => {
    const listener = e => {
      if (e.target === Transition.element) {
        Transition.element.removeEventListener(window.transitionEnd, listener)
        callback()
      }
    }

    Transition.element.addEventListener(window.transitionEnd, listener)
  },

  leave: data => {
    return new Promise(resolve => {
      Kernel.emitEvent('component.pagetransition.start')
      Transition.wait(resolve)
      Transition.element.parentNode.classList.add('transition--hide-content', 'transition--active')
      document.documentElement.classList.add('is-loading')
    })
  },

  afterLeave: data => {
    Kernel.emitEvent('component.pagetransition.leave')
    if (data.current && data.current.container) {
      data.current.container.remove()
    }

    if (data.next && data.next.container) {
      const scripts = Array.from(data.next.container.querySelectorAll('script')) || []

      scripts.forEach(script => {
        const tag = document.createElement('script')
        tag.src = script.src

        setTimeout(() => script.parentNode.appendChild(tag), 200)
      })

      // if (scripts.length > 0) {
      //   LoadJS(scripts.map(script => script.src))
      // }
    }

    Kernel.emitEvent('component.pagetransition.ready')
  },

  enter: data => {
    return new Promise(resolve => {
      Kernel.emitEvent('component.pagetransition.enter')
      Transition.wait(resolve)
      Transition.element.parentNode.classList.add('transition--reveal-content')
    })
  },

  afterEnter: data => {
    Kernel.emitEvent('component.pagetransition.end')
    Transition.element.parentNode.classList.remove('transition--hide-content', 'transition--active', 'transition--reveal-content')
    document.documentElement.classList.remove('is-loading')
  }
}

export const LayerPageTransition = {
  name: 'layer',
  leave: Transition.leave,
  afterLeave: Transition.afterLeave,
  enter: Transition.enter,
  afterEnter: Transition.afterEnter
}
