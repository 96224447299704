import Tippy from 'tippy.js'
import ClipboardJS from 'clipboard'

export class TocComponent {
  constructor () {
    this.selectors = {
      _self: '[data-toc]',
      anchor: '[data-toc-anchor]',
      item: '.item__link'
    }

    this.classes = { item: { active: 'item__link--active' } }
  }

  run () {
    this.elements = {
      _self: document.querySelector(this.selectors._self)
    }

    this.observer = null

    if (this.elements._self) {
      this.elements.items = this.elements._self.querySelectorAll(this.selectors.item)
      this.elements.anchors = document.querySelectorAll(this.selectors.anchor)

      if (this.elements.items.length > 0) {
        this.spy()
      }

      if (this.elements.anchors.length > 0) {
        this.setupAnchors()
      }
    }
  }

  spy () {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('id')

        if (entry.intersectionRatio > 0) {
          this.elements.items.forEach(item => item.classList.remove(this.classes.item.active))
          this.elements._self.querySelector(`a[href="#${id}"]`).classList.add(this.classes.item.active)
        }
        //  else {
        //   this.elements._self.querySelector(`a[href="#${id}"]`).classList.remove(this.classes.item.active)
        // }
      })
    })

    this.elements.items.forEach(item => {
      const target = document.querySelector(item.getAttribute('href'))

      if (target) {
        this.observer.observe(target)
      }
    })
  }

  setupAnchors () {
    this.elements.anchors.forEach(item => {
      const data = JSON.parse(item.dataset.tocAnchor)

      const tip = Tippy(item, {
        content: data.copy,
        hideOnClick: false,
        trigger: 'mouseenter',
        delay: 50
      })

      item.dataset.clipboardText = `${window.location.origin}${window.location.pathname}#${item.parentNode.getAttribute('id')}`

      const clip = new ClipboardJS(item)

      clip.on('success', e => {
        tip.setContent(data.copied)

        setTimeout(() => tip.setContent(data.copy), 2000)
      })
    })
  }
}
