export class TargetScroll {
  constructor () {
    this.selectors = {
      target: 'a[href^="#"][data-target-duration]'
    }

    this.duration = 800
  }

  run () {
    this.elements = {
      targets: document.querySelectorAll(this.selectors.target)
    }

    this.elements.targets.forEach(target => target.addEventListener('click', e => {
      const element = document.querySelector(target.getAttribute('href'))
      const duration = target.dataset && target.dataset.targetDuration ? target.dataset.targetDuration : this.duration

      if (element) {
        const box = element.getBoundingClientRect()
        this.scrollTo(box.y, duration)
        e.preventDefault()
      }
    }))
  }

  scrollTo (to, duration) {
    const
      element = document.scrollingElement || document.documentElement
    const start = element.scrollTop
    const change = to - start
    const startDate = +new Date()
    console.log(duration)
    // t = current time
    // b = start value
    // c = change in value
    // d = duration
    const easeInOutSine = function (t, b, c, d) {
      t /= d / 2
      if (t < 1) return c / 2 * t * t + b
      t--
      return -c / 2 * (t * (t - 2) - 1) + b
    }
    const animateScroll = function () {
      const currentDate = +new Date()
      const currentTime = currentDate - startDate
      element.scrollTop = parseInt(easeInOutSine(currentTime, start, change, duration))
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll)
      } else {
        element.scrollTop = to
        document.documentElement.classList.remove('no-smooth-scroll')
      }
    }
    document.documentElement.classList.add('no-smooth-scroll')
    animateScroll()
  }
}
