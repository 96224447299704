export class CTAFormComponent {
  constructor () {
    this.selectors = {
      _self: '[data-cta-form]',
      button: '[data-cta-form-button]'
    }
  }

  run () {
    this.elements = {
      _self: document.querySelectorAll(this.selectors._self)
    }

    this.elements._self.forEach(el => this.register(el))
  }

  register (el) {
    const buttonWrapper = el.querySelector(this.selectors.button)
    const button = buttonWrapper ? buttonWrapper.querySelector('[aria-controls]') : null

    if (button) {
      const formWrapper = document.getElementById(button.getAttribute('aria-controls'))

      if (formWrapper) {
        button.addEventListener('click', e => {
          button.setAttribute('aria-hidden', true)
          button.setAttribute('aria-expanded', true)
          button.setAttribute('hidden', true)

          formWrapper.setAttribute('aria-hidden', false)
          formWrapper.removeAttribute('hidden')
        })
      }
    }
  }
}
