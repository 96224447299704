import barba from '@barba/core'

import { LayerPageTransition } from 'components/ux/transitions/page/layer'

const Kernel = window.Kernel

export class PageTransition {
  constructor () {
    this.options = {
      debug: Kernel.debug,
      timeout: 6000,
      cacheIgnore: Kernel.debug,
      preventRunning: true,
      schema: { prefix: 'data-page-transition' },
      transitions: [LayerPageTransition],
      prefetchIgnore: true,
      prevent: data => this.prevent(data)
    }
  }

  run () {
    barba.init(this.options)

    barba.hooks.after(() => {
      // Analytics
      if (typeof window.ga !== 'undefined' && typeof window.location.pathname !== 'undefined') {
        window.ga('send', 'pageview', window.location.pathname)
      }
    })

    barba.hooks.afterLeave(() => {
      // Scroll to top
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    })
  }

  prevent (data) {
    return (data.el && data.el.getAttribute('href') && data.el.getAttribute('href').indexOf('#') >= 0 &&
    data.el.pathname === window.location.pathname)
  }
}
