export class LazySizes {
  constructor () {
    window.lazySizesConfig = window.lazySizesConfig || {}
    window.lazySizesConfig.lazyClass = 'image--lazyload'
    window.lazySizesConfig.loadedClass = 'image--lazyloaded'
    window.lazySizesConfig.loadingClass = 'image--lazyloading'
    window.lazySizesConfig.expand = 900
    window.lazySizesConfig.loadMode = 3
    window.lazySizesConfig.preloadAfterLoad = false
  }

  run () {
    require('lazysizes/lazysizes')
    require('lazysizes/plugins/parent-fit/ls.parent-fit')
    require('lazysizes/plugins/aspectratio/ls.aspectratio')
  }
}
