import Tippy from 'tippy.js'

export class TooltipComponent {
  constructor () {
    this.selectors = {
      _self: '[data-tooltip]'
    }
  }

  run () {
    this.elements = {
      _self: document.querySelectorAll(this.selectors._self)
    }

    if (this.elements._self.length > 0) {
      this.subscribe()
    }
  }

  subscribe () {
    this.elements._self.forEach(element => {
      Tippy(element, {
        content: element.dataset.tooltip,
        delay: 50
      })
    })
  }
}
