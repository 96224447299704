import { ModalObject } from 'objects/modal'

const Kernel = window.Kernel

export class SiteMenuComponent {
  constructor () {
    this.selectors = {
      modal: '#site-menu',
      toggle: '[aria-controls="site-menu"]'
    }

    this.classes = { modal: { active: 'modal--active' } }
    this.modal = null
  }

  run () {
    this.elements = {
      modal: document.querySelector(this.selectors.modal),
      toggle: document.querySelectorAll(this.selectors.toggle)
    }

    if (this.elements.toggle.length > 0 && this.elements.modal) {
      this.modal = new ModalObject(this.elements.modal, this.elements.toggle, this.classes, 'Menu')
      this.elements.toggle.forEach(el => el.addEventListener('click', () => {
        this.modal.toggle()
        Kernel.emitEvent(`component.menu.${this.modal.isOpen() ? 'open' : 'close'}`)
      }))
    }
  }
}
