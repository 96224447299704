const Kernel = window.Kernel

export class SiteHeaderComponent {
  constructor () {
    this.selectors = {
      _self: '.site-header:not(.site-header--bottom)',
      scroller: '[data-scrollbar="true"]'
    }

    this.subscribed = false
    this.scroller = null

    this.listeners = {
      scroller: status => this.onScroller(status),
      scroll: e => this.onScroll(e)
    }
  }

  run () {
    this.elements = {
      _self: document.querySelector(this.selectors._self),
      scroller: document.querySelector(this.selectors.scroller)
    }

    if (this.elements._self) {
      // if (this.scroller === null && this.elements.scroller !== null) {
      //   this.scroller = Scrollbar.get(this.elements.scroller)
      // }

      this.state = {
        hidden: false,
        offset: this.getOffsetTop()
      }

      if (this.subscribed === false) {
        this.subscribe()
      }
    }
  }

  subscribe () {
    if (this.scroller !== null) {
      this.scroller.addListener(this.listeners.scroller)
    } else {
      Kernel.subscribeTo('window.scroll', this.listeners.scroll)
    }
  }

  getOffsetTop () {
    return this.scroller ? this.scroller.scrollTop : (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop)
  }

  onScroller (status) {
    this.updateHeader(status.offset.y)
  }

  onScroll (e) {
    this.updateHeader(this.getOffsetTop())
  }

  updateHeader (offset) {
    if (this.state.offset - offset <= 0 && offset > 0 && !this.state.hidden) { // DOWN
      this.elements._self.setAttribute('hidden', true)
      this.state.hidden = true
    } else if (this.state.offset - offset > 0 && this.state.hidden) { // UP
      this.elements._self.removeAttribute('hidden')
      this.state.hidden = false
    }

    this.state.offset = offset
  }

  destroy () {
    Kernel.unsubscribeTo('window.scroll', this.listeners.scroll)
    if (this.scroller) {
      this.scroller.removeListener(this.listeners.scroller)
    }
  }
}
