import Slider from 'objects/slider'

export class TestimonialsSliderComponent {
  constructor () {
    this.selectors = {
      _self: '.slider--testimonials'
    }

    this.config = {
      wrapAround: true,
      autoPlay: false,
      draggable: true,
      adaptiveHeight: false,
      prevNextButtons: false,
      fade: true
    }
  }

  run () {
    this.sliders = []

    const elements = document.querySelectorAll(this.selectors._self)

    elements.forEach(el => this.sliders.push(new Slider(el, this.config)))
  }

  destroy () {
    this.sliders.forEach(slider => slider.destroy())
  }
}
