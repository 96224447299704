import { Kernel } from '../../../kernel'

export class HomePageComponent {
  constructor () {
    this.selectors = {
      _self: '.section--home',
      section: '.home__section',
      nav: {
        _self: '.home__navigation',
        item: '.navigation__item'
      }
    }

    this.classes = {
      loading: 'is-loading',
      intro: 'home-intro',
      item: {
        active: 'navigation__item--active'
      }
    }
  }

  run () {
    this.elements = {
      _self: document.querySelector(this.selectors._self),
      sections: document.querySelectorAll(this.selectors.section),
      intro: document.querySelector(this.selectors.intro),
      nav: {
        _self: document.querySelector(this.selectors.nav._self)
      }
    }

    if (this.elements._self) {
      document.documentElement.scrollTop = document.documentElement.scrollHeight
      document.documentElement.classList.remove(this.classes.loading, this.classes.intro)
    }

    if (this.elements.nav._self) {
      this.elements.nav.items = this.elements.nav._self.querySelectorAll(this.selectors.nav.item)
      this.spy()
    }
  }

  spy () {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('id')

        if (entry.intersectionRatio > 0) {
          this.elements.nav.items.forEach(item => item.classList.remove(this.classes.item.active))
          this.elements.nav._self.querySelector(`a[href="#${id}"]`).parentNode.classList.add(this.classes.item.active)
        }
      })
    }, {
      // root: this.elements._self, // set document viewport as root
      rootMargin: '-200px' // margin around root
    })

    this.elements.sections.forEach(section => this.observer.observe(section))
  }

  onScroll () {
    Kernel.emitEvent('window.scroll')
  }
}
