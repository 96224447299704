// Modules (Only run when DOM is ready)
// import { SmoothScroll } from 'components/ux/scroll/smooth'
import { PageTransition } from 'components/ux/transitions/page'

// Components (Only run when DOM is ready)
import { SiteMenuComponent } from 'components/menu/site'
import { SiteHeaderComponent } from 'components/header/site'
import { TocComponent } from 'components/toc'
import { TooltipComponent } from 'components/tooltip'
import { CTAFormComponent } from 'components/cta/form'
import { TestimonialsSliderComponent } from 'components/slider/testimonials'
import { HomePageComponent } from 'components/page/home'
import { ParallaxScroll } from 'components/ux/scroll/parallax'
import { TargetScroll } from 'components/ux/scroll/target'
import { InfographicComponent } from 'components/infographic'

const Kernel = window.Kernel
const payload = window.payload

class App {
  constructor (config, deps) {
    this.components = []
    this.modules = []
    this.config = config
    this.deps = deps
  }

  // Module and Component management
  addComponent (Component) {
    Kernel.log(`Add component '${Component.name}'`, 'app')
    this.components.push(new Component(this.config))
  }

  addModule (Module) {
    Kernel.log(`Add module '${Module.name}'`, 'app')
    this.modules.push(new Module(this.config))
  }

  loadComponents () {
    this.components.forEach(component => {
      Kernel.log(`Load component '${component.constructor.name}'`, 'app')
      try {
        component.run()
      } catch (e) {
        Kernel.log(`Fatal error in ${component.constructor.name}`, 'app', e)
      }
    })
  }

  unloadComponents () {
    this.components.forEach(component => {
      if (typeof component.destroy === 'function') {
        Kernel.log(`Unload component '${component.constructor.name}'`, 'app')
        component.destroy()
      }
    })
  }

  loadModules () {
    this.modules.forEach(module => {
      Kernel.log(`Load module '${module.constructor.name}'`, 'app')
      try {
        module.run()
      } catch (e) {
        Kernel.log(`Fatal error in ${module.constructor.name}`, 'app', e)
      }
    })
  }

  loadDependencies () {
    if (this.deps && this.deps.length > 0) {
      this.deps.forEach(item => Kernel.loadDependency(item))
    }
  }

  run () {
    if (Kernel.isDomReady()) {
      this.onDomReady()
    } else {
      Kernel.subscribeOnce('dom.ready', this.onDomReady.bind(this))
    }

    Kernel.subscribeTo('component.pagetransition.enter', () => this.onPageTransitionReady())
    Kernel.subscribeTo('component.pagetransition.leave', () => this.onPageTransitionLeave())
  }

  onDomReady () {
    this.loadModules()
    this.loadComponents()
    this.loadDependencies()
  }

  onPageTransitionReady () {
    this.loadComponents()
  }

  onPageTransitionLeave () {
    this.unloadComponents()
  }
}

// App Setup
const config = payload.app && payload.app.config ? payload.app.config : {}
const deps = payload.app && payload.app.deps ? payload.app.deps : {}
const app = new App(config, deps)

// app.addModule(SmoothScroll)
app.addModule(PageTransition)

app.addComponent(SiteMenuComponent)
app.addComponent(SiteHeaderComponent)
app.addComponent(TocComponent)
app.addComponent(TooltipComponent)
app.addComponent(CTAFormComponent)
app.addComponent(TestimonialsSliderComponent)
app.addComponent(HomePageComponent)
app.addComponent(ParallaxScroll)
app.addComponent(TargetScroll)
app.addComponent(InfographicComponent)

// Run app
app.run()
