import ClipboardJS from 'clipboard'

export default class InfographicObject {
  constructor (infographic) {
    this.selectors = {
      show: '[data-infographic-action="show"]',
      copy: '[data-infographic-action="copy"]'
    }

    this.elements = {
      _self: infographic,
      show: infographic.querySelector(this.selectors.show),
      copy: infographic.querySelector(this.selectors.copy)
    }

    if (this.elements.show) {
      this.elements.show.addEventListener('click', () => this.show())
    }

    if (this.elements.copy) {
      const clip = new ClipboardJS(this.elements.copy)

      clip.on('success', e => {
        this.elements.copy.innerText = this.elements.copy.dataset.infographicSuccess
        this.elements.copy.setAttribute('disabled', true)
      })
    }
  }

  show () {
    this.elements._self.dataset.infographic = 'expanded'
    window.dispatchEvent(new Event('resize'))
  }
}
