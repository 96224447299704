import { Kernel } from 'core/kernel'
import 'respimage'

export class ResponsiveImages {
  run () {
    this.subscribe()
  }

  subscribe () {
    Kernel.subscribeTo('dom.ready', this.onDomReady.bind(this))
    Kernel.subscribeTo('component.pagetransition.enter', this.onDomReady.bind(this))
  }

  onDomReady () {
    if (window.respimage) {
      window.respimage()
    }
  }
}
